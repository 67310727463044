import React from "react"
import Layout from "../../components/storyLayout"
import SEO from "../../components/seo"

const StoryPost = () => (
  <Layout storySrc="https://www.youtube.com/embed/G6yw2BNF-9c">
    <SEO title="Back to School Bash Recap - Phoenix - Story" />
  </Layout>
)

export default StoryPost
